import type { CSSProperties, FunctionComponent, PropsWithChildren, Ref } from 'react'

import { capitalize } from '@sporza/utils/strings'
import clsx from 'clsx'
import { useState } from 'react'
import { Item } from 'react-photoswipe-gallery'

import Caption from '../../atoms/caption'
import Icon from '../../atoms/icon'

import { ImageFocalPoint, ImageLayout } from './constants'
import { generateDefaultSrc, generateSizes, generateSrcSet, getImageProfiles } from './helpers'
import styles from './image.module.scss'

type ImageProfile = {
  name: string
  view: string
}

interface ImageComponentProps {
  componentName?: string
  componentType?: string
  componentProps: ImageProps
}

interface ImageProps extends PropsWithChildren {
  src?: string
  alt?: string
  title?: string
  caption?: string
  noCrop?: boolean
  noPlaceholder?: boolean
  boxed?: boolean
  focalPoint?: string
  fallbackProfile?: string
  profiles?: ImageProfile[]
  priority?: boolean
  layout?: string | ImageLayout
  className?: string
  darkMode?: boolean
  fallbackImage?: string
  style?: CSSProperties
  onClick?: (...args: any[]) => void
  innerRef?: Ref<HTMLElement>,
  addToGallery?: boolean
  useOutset?: boolean
  columns?: number
  url?: string
}

const Image: FunctionComponent<ImageProps> = (
  {
    src,
    alt,
    title,
    caption,
    noCrop,
    noPlaceholder,
    boxed = false,
    focalPoint,
    fallbackProfile = noCrop ? 'width960hx' : 'width960',
    profiles,
    priority = false,
    className,
    layout = ImageLayout.Widescreen,
    darkMode = false,
    children,
    fallbackImage,
    style,
    onClick,
    innerRef,
    addToGallery = false,
    useOutset = false,
    columns,
    url
  }
) => {
  const [initializedState, setInitializedState] = useState(priority)
  const [errorHandled, setErrorHandled] = useState(false)
  const defaultImageUrl = src ? generateDefaultSrc(src, fallbackProfile) : fallbackImage
  const imageProfiles = profiles
    ? profiles
    : getImageProfiles(layout, noCrop)

  const srcSet = src && generateSrcSet(src, imageProfiles).toString()
  const sizes = srcSet && generateSizes(columns)

  const Tag = url ? 'a' : 'div'

  const baseImage = (
    {
      ref = undefined,
      open = undefined
    }: any = {}
  ) => <figure
    key={defaultImageUrl}
    className={clsx(
      styles.imageContainer,
      layout === ImageLayout.Square && styles.square,
      layout === ImageLayout.Portrait && styles.portrait,
      layout === ImageLayout.Portrait9x16 && styles.portrait9x16,
      noCrop && styles.noCrop,
      initializedState && styles.imageContainerInitialized,
      darkMode && styles.imageContainerDark,
      useOutset && styles.imageContainerOutset,
      boxed && styles.boxed,
      className
    )}
  >
    <Tag
      href={url}
      target={url ? '_blank' : undefined}
      role={onClick || open ? 'button' : undefined}
      tabIndex={onClick || open ? 0 : undefined}
      className={clsx(
        styles.imageInner,
        onClick || open && styles.imageContainerClickable,
        noPlaceholder && styles.noPlaceholder
      )}
      onClick={onClick || open}
    >
      {children}

      {
        addToGallery
        && <Icon name="full-screen-expand" className={styles.fullScreenIcon} />
      }

      {
        defaultImageUrl
        && <img
          ref={innerRef || ref}
          src={defaultImageUrl}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
          title={title}
          loading={priority
            ? 'eager'
            : 'lazy'}
          decoding={priority
            ? 'async'
            : 'auto'
          }
          className={clsx(
            styles.image,
            focalPoint && styles[`imageFocalPoint${capitalize(focalPoint)}`]
          )}
          style={style}
          onLoad={() => setInitializedState(true)}
          onError={({ currentTarget }) => {
            // TODO: check if there is a native way to handle this
            if (errorHandled) {
              return
            }

            if (fallbackImage) {
              currentTarget.src = fallbackImage
              currentTarget.srcset = ''
            }

            setErrorHandled(true)
          }}
        />
      }
    </Tag>
    {caption
      && <figcaption className={styles.imageCaption}>
        <Caption>
          {caption}
        </Caption>
      </figcaption>
    }
  </figure>

  return addToGallery
    ? <Item
    original={defaultImageUrl?.replace(fallbackProfile, noCrop ? 'width1280hx' : 'width1280')}
    thumbnail={defaultImageUrl}
    alt={alt}
    caption={caption}
  >
    {({ ref, open }) => (
      baseImage({ ref, open })
    )}
  </Item>
    : baseImage()
}

export default Image

export {
  Image,
  ImageLayout,
  ImageFocalPoint
}

export type {
  ImageProfile,
  ImageProps,
  ImageComponentProps
}
