import { parseCacheControl } from '@sporza/utils/http'
import { keepPreviousData, QueryClient, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'

type UseApiProps = {
  interval?: number
  sportApiUrl?: string
}

type ApiConfig = {
  enableByStatus?: boolean
  intervalForStatus?: number
  sportApiUrlKey?: string
  url?: string
  defaultBaseUrl?: string
  fetchNow?: boolean
  select?: (data: any) => any
  keyPrefix?: any
}

const queryClient = new QueryClient()

if (typeof window !== 'undefined') {
  window.VRT = window.VRT || {}
  window.VRT.sporza = window.VRT.sporza || {}
  window.VRT.sporza.queryClient = queryClient || {}
}

const useApi = (
  initData: any = {},
  apiConfig: ApiConfig = {}
) => {
  const {
    enableByStatus = false,
    intervalForStatus = 10,
    url,
    sportApiUrlKey = 'sportApiUrl',
    defaultBaseUrl,
    fetchNow = false,
    keyPrefix,
    select
  } = apiConfig

  const [state, setState] = useState(initData)

  useEffect(() => {
    queryClient.removeQueries({ queryKey: keys, exact: true })
    setState(initData)
  }, [JSON.stringify(initData)])

  const {
    status,
    interval = enableByStatus && status?.toLowerCase() === 'live' && intervalForStatus || 0,
    [sportApiUrlKey]: sportApiUrl
  } = state

  const fetchUrl = url || sportApiUrl

  const queryParams = {
    refetchInterval: interval
      ? interval * 1000
      : undefined,
    enabled: typeof window !== 'undefined' && (fetchNow || Boolean(interval) || enableByStatus) && Boolean(fetchUrl)
  }

  const fetchProps = async (): Promise<any> => {
    if (!fetchUrl) {
      return
    }

    const fetchUrlObj = new URL(fetchUrl, defaultBaseUrl)
    const params: Record<any, any> = {}

    if (fetchUrlObj.searchParams.get('author') === 'true') {
      fetchUrlObj.searchParams.set('t', Date.now().toString())
    }

    return await axios.get(
      fetchUrlObj.toString() || '',
      {
        params: params
      }
    )
      .then(response => {
        if (enableByStatus) {
          const cacheControl = parseCacheControl(response.headers?.['cache-control'] || '')

          if (cacheControl && Number.isInteger(cacheControl['max-age'])) {
            setState({ ...state, interval: cacheControl['max-age'] })
          }
        }

        return response.data.componentProps ? response.data.componentProps : response.data
      })
  }

  const getKeys = () => {
    switch (true){
      case keyPrefix !== undefined && fetchUrl !== undefined:
        return [keyPrefix, fetchUrl]
      case fetchUrl !== undefined:
        return [fetchUrl]
      default:
        return [initData] // prevent undefined key, needs to be unique to component
    }
  }

  const keys = getKeys()

  const {
    isPending,
    isFetching,
    isError,
    data,
    error,
    refetch
  } = useQuery({
    queryKey: keys,
    queryFn: fetchProps,
    initialData: initData,
    placeholderData: keepPreviousData,
    select,
    ...queryParams,
  }, queryClient)

  useEffect(() => {
    if (!isPending) {
      if (enableByStatus || fetchNow) {
        // do nothing because we want to try out continious polling on status based components
      } else if (data?.interval === undefined) {
        setState({ ...state, interval: 0 })
      } else if (data?.interval !== interval) {
        setState({ ...state, interval: data?.interval })
      }
    }
  }, [interval])

  return {
    isPending,
    isFetching,
    isError,
    data: typeof window === 'undefined' ? initData : (data || state), // just give initial props when SSR
    error,
    isEnabled: Boolean(interval),
    refetch
  }
}

export {
  useApi
}

export type {
  UseApiProps
}
